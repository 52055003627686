<template>
  <div class="flex-col page">
      <div class="flex-row justify-between items-center fixed_top">
        <div class="flex-row justify-center items-center" style="margin-left: 1.5rem">
          <img
              class="image_2"
              :src="require('@/assets/logo.png')"
          />
          <img
              class="image_4"
              style="margin-left: 0.25rem"
              :src="require('@/assets/MAKAFIM.png')"
          />
        </div>
        <div style="position: relative;margin-right: 1.5rem">
          <img
              v-show="!showRoute"
              class="image_3"
              :src="require('@/assets/spread.png')"
              @click="showRoute=!showRoute"
          />
          <img
              v-show="showRoute"
              class="image_3"
              :src="require('@/assets/delete.png')"
              @click="showRoute=!showRoute"
          />
          <ul class="ul_router" v-if="showRoute" >
            <li @click="$router.push('/home/index')">首页</li>
            <li @click="$router.push('/home/instruction_manual')">使用手册</li>
<!--            <li @click="$router.push('/home/maka_club')">麦嘉俱乐部</li>-->
            <li @click="$router.push('/home/about_us')">关于我们</li>
            <li @click="$router.push('/home/register')">注册</li>
          </ul>
        </div>
      </div>
    <keep-alive>
      <router-view v-if="$route.meta.KeepAlive"/>
    </keep-alive>
      <router-view v-if="!$route.meta.KeepAlive"/>
    <div style="position: relative">
      <div style="position:fixed;left:0;top:0;width: 100%;height: 100%;background:rgba(0,0,0,0.3);overflow: hidden" v-if="showRoute"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StaticPage.vue",
  data(){
    return {
      showRoute:false,
    }
  },
  watch:{
    '$route.path':function (val){
      this.showRoute=false
    }
  },
  mounted(){
    if(this.$route.query.makafin_source){
      this.$utils.CacheSessionSet('query',this.$route.query)
    }
    document.querySelector('#ttkefuico').style.width= '1.5rem';
    document.querySelector('#ttkefuico').style.height= '5.3125rem';
    document.querySelector('#ttkefuico').style.fontSize= '1rem';
    document.querySelector('#ttkefuico #ttkefu_Mb_Ele_0').style. textAlign= 'center';
    document.querySelector('#ttkefuico #ttkefu_Mb_Ele_0').style.lineHeight= '1.125rem';
    document.querySelector('#ttkefuico #ttkefu_Mb_Ele_0').style.padding= '0.5rem 0 0 0';
    document.querySelector('#ttkefuico #ttkefu_Mb_Ele_0').style.fontSize= '1rem';
  },
  methods:{

  }
}
</script>
<style>
.mobile_input .el-input__inner{
  font-size: 0.875rem;
  height: 2.5rem;
}
.el-message-box{
  width: auto;
}
.ul_router{
  position: absolute;
  width: 10rem;
  right:-94%;
  top:2.2rem;
  font-size: 0.875rem;
  line-height: 3.5rem;
  background: #FFFFFF;
  text-align: center;
}
.ul_router::before{
  content: '';
  position: absolute;
  top:-20px;
  right:25px;
  border: 10px solid transparent;
  border-bottom: 20px solid white;
}
.ul_router>li{
  box-sizing: border-box;
  border-bottom: 1px solid #DEDEDE;
}
.ul_router>li:nth-child(1){
  border-top: 1px solid #DEDEDE;
}
.image_1{
  width: 100%;
  height: 100%;
}
.image_2{
  width: 100%;
  height: 100%;
}
.image_3{
  width: 100%;
  height: 100%;
}
.image_4{;
}
.fixed_top{
  position:fixed;
  width: 100%;
  padding:0.5rem 0;
  background:#fff;
  border-bottom: 1px solid #f2f2fa;
  z-index: 999999;
}
.flex-col{
  display: flex;
  flex-direction: column;
}
.flex-row{
  display: flex;
}
.justify-between{
  justify-content: space-between;
}
.justify-center{
  justify-content: center;
}
.justify-start{
  justify-content: flex-start;
}
.items-center{
  align-items: center;
}
.self-center{
  align-self: center;
}
.page {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.image {
  width: 23.44rem;
  height: 2.75rem;
}
.group {
  padding: 0 1.5rem;
}
.space-x-5 > *:not(:first-child) {
  margin-left: 0.31rem;
}
.image_2 {
  width: 1.69rem;
  height: 2rem;
}
.image_4 {
  width: 6rem;
  height: 1.13rem;
}
.image_3 {
  width: 1.5rem;
  height: 1.5rem;
}
.group_2 {
  margin-top: 3rem;
  padding-left: 2.75rem;
  padding-right: 2.63rem;
}
.space-y-14 > *:not(:first-child) {
  margin-top: 0.88rem;
}
.font_1 {
  font-size: 1.75rem;
  font-family: Microsoft YaHei;
  line-height: 1.72rem;
  font-weight: 700;
  color: #333333;
}
.text {
  color: #6162ff;
  line-height: 1.66rem;
}
.group_3 {
margin-bottom:0.875rem ;
}
.space-x-14 > *:not(:first-child) {
  margin-left: 0.88rem;
}
.font_2 {
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  line-height: 0.72rem;
  color: #333333;
}
.group_4 {
  padding: 0 3.5rem;
}
.space-y-26 > *:not(:first-child) {
  margin-top: 1.63rem;
}
.font_3 {
  font-size: 1.25rem;
  font-family: Microsoft YaHei;
  line-height: 1.28rem;
  font-weight: 700;
  color: #333333;
}
.text_2 {
  line-height: 1.22rem;
}
.font_4 {
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  line-height: 0.72rem;
  font-weight: 700;
  color: #333333;
}
.text_4 {
  margin-top: 0.75rem;
}
.text_5 {
  margin-top: 0.75rem;
  color: #333333;
  font-size: 1.75rem;
  font-family: Times New Roman;
  font-weight: 700;
  line-height: 1.59rem;
}
.button {
  margin-top: 0.88rem;
}
.group_5 {
  padding: 1rem 3.13rem 0;
}
.image_7 {
  margin-top: 3.125rem;
  width: 20.44rem;
  height: 11.5rem;
}
.group_6 {
  margin-top: 2.5rem;
}
.group_7 {
  padding-top: 0.5rem;
}
.section {
  padding: 2rem 1.5rem 2.94rem;
  background-color: #ffffff;
}
.space-y-22 > *:not(:first-child) {
  margin-top: 1.38rem;
}
.text_6 {
  color: #666666;
  line-height: 0.75rem;
}
.grid {
  height: 15.69rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.grid-item {
  padding: 1.13rem 0;
}
.image_8 {
  width: 3rem;
  height: 3rem;
}
.text_7 {
  margin-top: 0.88rem;
}
.font_6 {
  font-size: 0.5rem;
  font-family: Microsoft YaHei;
  line-height: 0.5rem;
  color: #666666;
}
.text_8 {
  margin-top: 0.5rem;
}
.text_9 {
  color: #5d5d5d;
}
.divider {
  background-color: #efefef;
  height: 0.063rem;
}
.pos_3 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.section_2 {
  background-color: #efefef;
  height: 15.69rem;
}
.pos_2 {
  position: absolute;
  left: 10.22rem;
  right: 10.22rem;
  top: 0;
}
.pos {
  position: absolute;
  right: 8.84rem;
  top: 0;
}
.section_3 {
  padding: 2.25rem 1.5rem;
  background-color: #1f2225;
}
.space-y-34 > *:not(:first-child) {
  margin-top: 2.13rem;
}
.text_10 {
  color: #ffeead;
  line-height: 1.22rem;
}
.space-y-19 > *:not(:first-child) {
  margin-top: 1.19rem;
}
.space-y-12 > *:not(:first-child) {
  margin-top: 0.75rem;
}
.list-item {
  height: 6.22rem;
}
.section_4 {
  padding: 1.13rem 0.75rem 0.88rem;
  border-radius: 0.25rem;
  background: #2F3237;
}
.font_7 {
  font-size: 1rem;
  line-height: 0.97rem;
  font-weight: 700;
  color: #ffeead;
}
.font_8 {
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  line-height: 1.13rem;
  color: #a7a7a7;
}
.text_11 {
  width: 15.75rem;
}
.text_12 {
  margin-top: -1.88rem;
  opacity: 0.06;
}
.text_13 {
  margin-top: -2rem;
}
.space-y-10 > *:not(:first-child) {
  margin-top: 0.63rem;
}
.text_16 {
  color: #d1d1d1;
  line-height: 0.75rem;
}
.group_8 {
  padding: 0 1.56rem;
}
.section_6 {
  padding: 0.5rem 0;
  border-radius: 0.25rem;
  height: 2.38rem;
  border: solid 0.063rem #ffeead;
}
.section_7 {
  background-color: #ffeead;
  width: 0.063rem;
  height: 0.5rem;
}
.group_9 {
  padding: 0.63rem 0;
  width: 1.56rem;
}
.divider_2 {
  background-color: #666666;
  width: 1.56rem;
  height: 0.063rem;
}
.font_10 {
  font-size: 1.88rem;
  font-family: Times New Roman;
  line-height: 1.28rem;
  font-weight: 700;
  color: #ffeead;
}
.text_17 {
  width: 0.88rem;
}
.section_8 {
  padding: 4.25rem 0 4.69rem;
  background-color: #ffffff;
}
.space-y-33 > *:not(:first-child) {
  margin-top: 2.06rem;
}
.text_18 {
  line-height: 1.22rem;
}
.group_10 {
  margin-left: -5.5rem;
  width: 34.56rem;
}
.image_9 {
  width: 7.5rem;
  height: 2.06rem;
}
.image_10 {
  width: 6.25rem;
  height: 2.06rem;
}
.image_11 {
  width: 8rem;
  height: 2.06rem;
}
.image_12 {
  width: 8.31rem;
  height: 2.06rem;
}
.group_11 {
  margin-left: -0.81rem;
  width: 29.38rem;
}
.space-x-24 > *:not(:first-child) {
  margin-left: 1.5rem;
}
.image_13 {
  width: 6.88rem;
  height: 2.06rem;
}
.image_14 {
  width: 10.19rem;
  height: 2.06rem;
}
.image_15 {
  width: 9.31rem;
  height: 2.06rem;
}
.section_9 {
  padding: 2.63rem 1.5rem 0;
  background-color: #1f2225;
}
.space-x-4 > *:not(:first-child) {
  margin-left: 0.25rem;
}
.image_16 {
  width: 1.88rem;
  height: 2.25rem;
}
.image_17 {
  width: 8rem;
  height: 1.5rem;
}
.group_12 {
  margin-top: 1.38rem;
}
.text_19 {
  color: #ffffff;
  line-height: 0.88rem;
}
.button_2 {
  align-self: flex-start;
  margin-top: 1.88rem;
}
.image_18 {
  margin: 0.13rem 0.25rem 0 1.25rem;
  width: 6rem;
  height: 6rem;
}
.image-wrapper {
  margin-top: 1.88rem;
  padding: 1rem 0;
  border-top: solid 0.063rem #2f3237;
}
.image_19 {
  width: 14.25rem;
  height: 0.81rem;
}
</style>